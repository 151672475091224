import React from "react"
import { useContext } from "react"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "./dull-moment.scss"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

export default function DullMoment(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      gallery_title_ar:translateString(language: AR, string: "NEWSROOM GALLERY TITLE")
      gallery_title_en:translateString(language: EN, string: "NEWSROOM GALLERY TITLE")
    }
  }`)
  const shouldRenderArrows = props.data.length > 4

  const swiperRef = React.useRef(null)
  return (
    <>
      <section className="never-dull-section">
        <div className="container">
          <div className="heading-wrapper">
            <div className="bike">
              <img src="/images/diversity/bike-line.svg" alt="Bike Line" />
            </div>
            <div className="box-wrapper">
              <div className="text">{string_translation(stringTranslations,"gallery_title", locale)}</div>
            </div>
          </div>
        </div>
        <div className="dull-slider">
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }}
            id="dullslider"
            spaceBetween={23}
            slidesPerView={1.2}
            slidesPerGroup={1}
            grabCursor={"true"}
            ref={swiperRef}
            breakpoints={{
              540: {
                slidesPerView: 1.6,
                slidesPerGroup: 2,
              },
              700: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              900: {
                slidesPerView: 2.5,
                slidesPerGroup: 2,
              },
              1100: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1400: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
            }}
            dir={locale === "en" ? "ltr" : "rtl"}

          >
            {props.data.map((item, index) => (
            <SwiperSlide className="swiper-slide" key={index}>
              <div className="img-wrapper">
                <img src={item?.localFile?.publicURL} alt="Dull Moment" />
              </div>
            </SwiperSlide>
            ))}
            <div className="custom-nav right">

            {shouldRenderArrows && <SliderNavigation mobile="true" ref={swiperRef} />}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  )
}
