import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import CareerFirstComponent from "../components/CareerFirstComponent"
import SliderComponent from "../components/SliderComponent"
import LifeSlider from "../components/LifeSlider"
import Diversity from "../components/Diversity"
import DullMoment from "../components/DullMoment"
import CallToActionBanner from "../components/CallToActionBanner"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
export default function Career(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
        />
        <CareerFirstComponent data={data?.wpPage}/>
        {(data?.wpPage?.careerFields?.callToActionBanner)?<CallToActionBanner data={data?.wpPage?.careerFields?.callToActionBanner}/>:''}

        {(data.allWpCareer.edges.length>1)?
          <SliderComponent data={data.allWpCareer} locale={locale}/>:''
        }
        <LifeSlider data={data.wpPage.careerFields.slider} locale={locale}/>
        {(data.wpPage.careerFields.diversity)?<Diversity data={data?.wpPage.careerFields.diversity} locale={locale}/>:''}
        
        {(data?.wpPage?.careerFields?.itsNeverADullMoment?.length>1)?
          <DullMoment data={data.wpPage.careerFields.itsNeverADullMoment} locale={locale}/>:''
        }
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query careerData($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      pageSubtitle {
        fieldGroupName
        subtitle
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      pageDescription {
        pageDescription
      }
      careerFields {
        fieldGroupName
        itsNeverADullMoment {
          localFile {
            publicURL
          }
        }
        slider {
          image {
            localFile {
              publicURL
            }
          }
          text
          title
        }
        diversity {
          color
          image {
            localFile {
              publicURL
            }
          }
          number
          position
          text
        }
        callToActionBanner {
          buttonLink
          buttonText
          description
          title
        }
        mobileFeaturedImage {
          localFile{
            publicURL
          }
        }
      }
    }
    allWpCareer {
      edges {
        node {
          id
          date(formatString: "DD-MMMM-YYYY")
          title
          content
          fieldsForJobPost {
            expiryDate
            jobApplyLink
          }
        }
      }
    }
  }
`
