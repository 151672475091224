import React from "react"
import { useContext } from "react"
import { string_translation } from "../../../utils"
import { useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import "./diversity.scss"

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/scrollbar"

export default function Diversity({ data }) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        title_ar: translateString(
          language: AR
          string: "DIVERSITY INCLUSION BLOCK TITLE"
        )
        title_en: translateString(
          language: EN
          string: "DIVERSITY INCLUSION BLOCK TITLE"
        )
      }
    }
  `)

  return (
    <>
      <section className="diversity">
        <div className="container">
          <div className="heading h2">
            {string_translation(stringTranslations, "title", locale)}
            <div className="logos">
              <div className="logo">
                <img src="/images/career/search-logo.svg" alt="Search Logo" />
              </div>
              <div className="logo desktop-show">
                <img src="/images/career/search-logo.svg" alt="Search Logo" />
              </div>
              <div className="logo desktop-show">
                <img src="/images/career/search-logo.svg" alt="Search Logo" />
              </div>
              <div className="logo desktop-show">
                <img src="/images/career/search-logo.svg" alt="Search Logo" />
              </div>
            </div>
          </div>
          <Swiper
            id="diversity-swiper"
            spaceBetween={24}
            grabCursor={"true"}
            freeMode={"true"}
            breakpoints={{
              300: {
                slidesPerView: 1.2,
              },
              540: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={"diversity-wrapper " + item.color}>
                  <div className="img-wrapper">
                    <img src={item.image?.localFile?.publicURL} alt="DIVERSITY" />
                  </div>
                  <div className="text-wrapper">
                    <div className="big-title h2">{item.number}</div>
                    <div className="small-text">{item.text}</div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  )
}
