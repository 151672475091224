import React from "react"
import "./lifeslider.scss"

// import Swiper core and required modules
import { EffectFade, Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { createMarkup } from "../../../utils"

export default function LifeSlider(props) {
  const swiperRef = React.useRef(null)
  const { data, locale } = props

  const shouldRenderArrows = data?.length > 1

  return (
    <>
      <section className="divers-slider-section">
        <div className="container">
          <Swiper
            // install Swiper modules
            modules={[EffectFade, Navigation, Pagination]}
            pagination={{
              clickable: true,
            }}
            id="divers-swiper"
            slidesPerView={1}
            slidesPerGroup={1}
            grabCursor={"true"}
            effect={"fade"}
            ref={swiperRef}
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            
            {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="col-group">
                <div className="col-dt-6">
                  <div className="wrapper">
                    <div className="img-wrapper">
                      <img src={item.image?.localFile?.publicURL} alt={item.title} />
                    </div>
                  </div>
                </div>
                <div className="col-dt-6">
                  <div className="text-wrapper">
                    <div className="big-title h2">
                      {item.title}
                    </div>
                    <div className="desc" dangerouslySetInnerHTML={createMarkup(item.text)}>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            ))}
            {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}
          </Swiper>
        </div>
      </section>
    </>
  )
}
