import React, { useState, useEffect, useContext } from "react"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "./slidercomponent.scss"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import { Link } from "gatsby"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { createMarkup } from "../../../utils"

export default function SliderComponent(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      latest_updates_ar:translateString(language: AR, string: "CAREER LATEST UPDATES")
      latest_updates_en:translateString(language: EN, string: "CAREER LATEST UPDATES")
      search_ar:translateString(language: AR, string: "SEARCH")
      search_en:translateString(language: EN, string: "SEARCH")
      sort_ar:translateString(language: AR, string: "SORT BY")
      sort_en:translateString(language: EN, string: "SORT BY")
      apply_ar:translateString(language: AR, string: "APPLY")
      apply_en:translateString(language: EN, string: "APPLY")
      search_placeholder_updates_ar:translateString(language: AR, string: "SEARCH BY JOB TITLE")
      search_placeholder_updates_en:translateString(language: EN, string: "SEARCH BY JOB TITLE")
      newest_en:translateString(language: EN, string: "NEWEST")
      newest_ar:translateString(language: AR, string: "NEWEST")
      oldest_en:translateString(language: EN, string: "OLDEST")
      oldest_ar:translateString(language: AR, string: "OLDEST")
      alphabetical_en:translateString(language: EN, string: "ALPHABETICAL")
      alphabetical_ar:translateString(language: AR, string: "ALPHABETICAL")
    }
  }`)

  const swiperRef = React.useRef(null)
  let textInput = React.createRef()
  

  const career = props.data.edges

  const [cdata, setData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [sortType, setSortType] = useState("newest")
  const shouldRenderArrows = cdata.length > 2

  useEffect(() => {
    const sortArray = type => {
      const types = {
        alphabetical: "node",
        newest: "node",
        oldest: "node",
      }
      const sortProperty = types[type]
      const sorted = [...career].sort((a, b) => {
        if (sortProperty === "node" && sortType === "alphabetical") {
          return a?.node?.title.localeCompare(b?.node?.title)
        } else if (sortProperty === "node" && sortType === "oldest") {
          return a.node.date.localeCompare(b.node.date)
        } else {
          return b.node.date.localeCompare(a.node.date)
        }
      })
      setData(sorted)
    }
    sortArray(sortType)
  }, [sortType])

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setSearchValue(textInput.current.value)
      }
    }
  return (
    <>
      <section className="career-slider-section">
        <div className="container">
          <div className="heading h2">{string_translation(stringTranslations,"latest_updates", locale)}</div>
          <div className="search-wrapper">
            <div className="search-bar">
              <input
                type="text"
                placeholder={string_translation(stringTranslations,"search_placeholder_updates", locale)}
                name="search"
                ref={textInput}
                onKeyDown={handleKeyDown}
                // onChange={e => setSearchValue(e.target.value)}
              />
              <button
                className="search-btn-selection"
                onClick={e => setSearchValue(textInput.current.value)}
              >
                {string_translation(stringTranslations,"search", locale)}
              </button>
            </div>
            <div className="logos-wrapper">
              <div className="col-group">
                <div className="col-dt-9">
                  <div className="logos">
                    <div className="logo">
                      <img src="/images/career/search-logo.svg" alt="Search Logo" />
                    </div>
                    <div className="logo">
                      <img src="/images/career/search-logo.svg" alt="Search Logo" />
                    </div>
                    <div className="logo">
                      <img src="/images/career/search-logo.svg" alt="Search Logo" />
                    </div>
                    <div className="logo desktop-show">
                      <img src="/images/career/search-logo.svg" alt="Search Logo" />
                    </div>
                    <div className="logo desktop-show">
                      <img src="/images/career/search-logo.svg" alt="Search Logo" />
                    </div>
                  </div>
                </div>
                <div className="col-dt-3 sorting-big-wrapper">
                  <div className="sorting-wrapper">
                    <div className="sorting-tag">
                      <div className="text">{string_translation(stringTranslations,"sort", locale)}</div>
                      <select onChange={e => setSortType(e.target.value)}>
                        <option value="newest">{string_translation(stringTranslations,"newest", locale)}</option>
                        <option value="oldest">{string_translation(stringTranslations,"oldest", locale)}</option>
                        <option value="alphabetical">{string_translation(stringTranslations,"alphabetical", locale)} </option>
                      </select>
                      <div className="dropdown-arrow">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12.7891" r="12" fill="#3D3935" />
                          <path
                            d="M11.4999 15.7891L11.88 15.4407L16 11.7245L15.2398 10.7891L11.5 14.1633L7.76023 10.7891L7 11.7245L11.12 15.4407L11.4999 15.7891Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="sorting-tags"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                return '<span className="' + className + '">' + "</span>"
              },
            }}
            id="career-swiper"
            spaceBetween={24}
            slidesPerView={1}
            slidesPerGroup={1}
            grabCursor={"true"}
            ref={swiperRef}
            breakpoints={{
              992: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
            }}
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            {cdata
              .filter(item =>
                item?.node?.title.match(new RegExp(searchValue, "i"))
              )
              .map((item, index) => (
                <SwiperSlide id={index}>
                  <div className="swiper-slide-big-wrapper">
                    <div className="swiper-slide-wrapper">
                      <div className="col-group">
                        <div className="col-dt-4">
                          <div className="big-title h2">{item?.node?.title}</div>
                          <div className="date">{item.node.date}</div>
                        </div>
                        <div
                          className="col-dt-8 right-col"
                          dangerouslySetInnerHTML={createMarkup(
                            item.node.content
                          )}
                        ></div>
                      </div>
                    </div>
                    {/* skyblue */}
                    <div className="banner coral ">14 days left</div>
                  </div>
                  <div className="apply-btn">
                    <Link to={item.node.fieldsForJobPost.jobApplyLink}>{string_translation(stringTranslations,"apply", locale)}</Link>
                  </div>
                </SwiperSlide>
              ))}

            {shouldRenderArrows && <SliderNavigation mobile="true" ref={swiperRef} />}

            <div className="swiper-pagination"></div>
          </Swiper>
        </div>
      </section>
    </>
  )
}
